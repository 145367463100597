export default {
  languages: {
    english: 'ENGLISH',
    korean: '한글',
    spanish: 'ESPAÑOL',
    chinese: '中文'
  },
  language: {
    english: 'LANGUAGE',
    korean: '언어',
    spanish: 'IDIOMA',
    chinese: '语言'
  }
}